export const ClassProjectsList = [
  {
    name: "CS355 Internet & Web Technologies Projects",
    sourcecode: "https://github.com/ycorcos1/Internet_and_Web_Technologies",
  },
  {
    name: "CS323 Designs & Analysis Algorithms",
    sourcecode:
      "https://github.com/ycorcos1/CS323-Design_and_Analysis_of_Algorithms",
  },
];
